import { FC, useState, useCallback } from 'react';
import Typography from '@mui/joy/Typography';
import Button from '@mui/joy/Button';
import usePropData from '../hooks/usePropData';
import Box from '@mui/joy/Box';
import IconButton from '@mui/joy/IconButton';
import CloseRounded from '@mui/icons-material/CloseRounded';
import Chip from '@mui/joy/Chip';
import ChipDelete from '@mui/joy/ChipDelete';
import AccordionGroup from '@mui/joy/AccordionGroup';
import Accordion from '@mui/joy/Accordion';
import AccordionDetails from '@mui/joy/AccordionDetails';
import AccordionSummary from '@mui/joy/AccordionSummary';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import Link from '@mui/joy/Link';
import Divider from '@mui/joy/Divider';
import AddRounded from '@mui/icons-material/AddRounded';
import Stack from '@mui/joy/Stack';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import ModalClose from '@mui/joy/ModalClose';
import ImageCarousel from './ImageCarousel';
import ImageRounded from '@mui/icons-material/ImageRounded';
import BookmarkAdd from '@mui/icons-material/BookmarkAddOutlined';
import BookmarkAddedRoundedIcon from '@mui/icons-material/BookmarkAddedRounded';
import LocationOnRounded from '@mui/icons-material/LocationOnRounded';
import Autocomplete, { createFilterOptions } from '@mui/joy/Autocomplete';
import AutocompleteOption from '@mui/joy/AutocompleteOption';
import { updateProperty } from '../api/properties.api';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import Add from '@mui/icons-material/Add';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { addTag } from '../api/tags.api';

interface TagOptionType {
  inputValue?: string;
  tag_id?: string;
  name: string;
}

const tagFilter = createFilterOptions<TagOptionType>();

interface PropertyDetailProps {
  onClose: () => void;
}

interface TextRowProps {
  label: string;
  value?: string;
  info?: string;
  bold?: boolean;
}

const TextRow: FC<TextRowProps> = ({ label, value, info, bold }) => {
  const [infoOpen, setInfoOpen] = useState(false);
  return (
    <Box key={`${Math.random()}`}>
      <Typography
        level="body-sm"
        sx={{ pl: 0, ml: 0, fontWeight: bold ? 'bold' : 'normal' }}
        endDecorator={
          info && (
            <IconButton size="sm" onClick={() => setInfoOpen((prev) => !prev)}>
              <InfoOutlinedIcon fontSize="small" />
            </IconButton>
          )
        }
      >
        {label}
      </Typography>
      {infoOpen && <Typography level="body-xs">{info}</Typography>}
      {value && (
        <Typography level="body-md" sx={{ mb: 0.5 }}>
          {value}
        </Typography>
      )}
    </Box>
  );
};

function toTitleCase(str: string) {
  if (!str) return '';
  return str.replace(/\w\S*/g, (text) => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase());
}

const PropertyDetail: FC<PropertyDetailProps> = ({ onClose }) => {
  const [openModal, setOpenModal] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [error, setError] = useState('');
  const [tagValue, setTagValue] = useState<TagOptionType | null>(null);
  const [isAddTag, setIsAddTag] = useState(false);
  const { state, dispatch } = usePropData();
  const { selectedProperty, currentTags } = state;

  console.log(selectedProperty);
  // warning: tags are ids
  const {
    property_id,
    urls,
    scraped_address,
    gmap_address,
    tags,
    description,
    nearby_stations,
    brochures,
    images,
    features,
    property_types,
    article4,
    article4_est,
    listed_grade,
    tenure,
    top_parent_uprns,
    prop_count_uprn,
    lease_count_uprn,
    perc_leased_uprn,
    has_listed_uprn,
    has_listed_cad,
    saved,
    last_seen,
    price,
    min_area,
    max_area,
    min_psf,
    max_psf,
    date_added,
  } = selectedProperty;

  const handleViewOnMap = useCallback(() => {
    dispatch({ type: 'SET_VIEW_ON_MAP_ID', payload: property_id });
  }, [property_id, dispatch]);

  const onAddTag = async (tagName: string) => {
    const currTagNames = tags.map((tagId: string) => currentTags.get(tagId)?.name); // tag name should always exist
    if (currTagNames.includes(tagName)) {
      setError('Tag already exists');
      setOpenErrorModal(true);
      return;
    }
    setError('');

    // create new tag?
    let newTag = Array.from(currentTags.values()).find((tag: any) => tag.name === tagName);
    if (!newTag) {
      newTag = await addTag(tagName);
      dispatch({ type: 'ADD_TAG', payload: newTag });
    }

    // update property
    dispatch({ type: 'UPDATE_SELECTED_PROPERTY', payload: { tags: [...tags, newTag.tag_id] } });
    setIsAddTag(false);
    await updateProperty(property_id, { tags: [...tags, newTag.tag_id] });
  };

  const onRemoveTag = async (tagId: string) => {
    if (!tags.includes(tagId)) return;
    const newTags = tags.filter((t: string) => t !== tagId);
    dispatch({ type: 'UPDATE_SELECTED_PROPERTY', payload: { tags: newTags } });
    await updateProperty(property_id, { tags: newTags });
  };

  const handleBookmark = async () => {
    const newProperty = { ...selectedProperty, saved: !saved };
    dispatch({ type: 'UPDATE_SELECTED_PROPERTY', payload: { saved: newProperty.saved } });
    dispatch({ type: 'ADD_PROPERTIES', payload: [newProperty] });
    await updateProperty(property_id, { saved: newProperty.saved });
  };

  const ImageModal: FC<{ images: string[] }> = ({ images }) => (
    <Modal open={openModal} onClose={() => setOpenModal(false)}>
      <ModalDialog
        variant="outlined"
        sx={{
          width: 800,
          borderRadius: 'md',
          p: 3,
          boxShadow: 'lg',
        }}
        layout="center"
      >
        <ModalClose variant="plain" sx={{ m: 1 }} />
        <Typography component="h2" level="h4" mb={1}>
          Images
        </Typography>
        <ImageCarousel images={images} height="400px" />
      </ModalDialog>
    </Modal>
  );

  if (!selectedProperty) return null;

  const area = min_area === null ? 'Unknown area' : `${min_area}-${max_area} sqft`;
  const psf = min_psf === null ? 'Unknown psf' : `${min_psf}-${max_psf} £/sqft`;
  const priceStr =
    price === null ? 'Unknown price' : price.toLocaleString('en-GB', { style: 'currency', currency: 'GBP' });
  
  const article4Val = article4 ?? article4_est;
  const article4Str = article4Val === null ? 'Unknown' : article4Val ? 'Restricted' : 'Unrestricted';

  return (
    <>
      <ImageModal images={images} />
      <Box
        sx={{
          p: 2,
          pt: 0,
          height: '100%',
          overflowY: 'auto',
          backgroundColor: 'white',
          borderRadius: '5px',
          '&::-webkit-scrollbar': { display: 'none' },
          msOverflowStyle: 'none',
          scrollbarWidth: 'none',
        }}
      >
        {/* Header */}
        <Box
          sx={{
            position: 'sticky',
            top: 0,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 2,
            backgroundColor: 'white',
            p: 1,
            pl: 0,
            zIndex: 100,
          }}
        >
          <Stack direction="row" spacing={1} sx={{ maxWidth: 300, display: 'flex', alignItems: 'center' }}>
            <Typography level="body-md" fontWeight="bold">
              Property Details
            </Typography>
            <IconButton
              variant="plain"
              color="neutral"
              size="sm"
              onClick={handleBookmark}
              disabled={!state.properties.has(property_id)}
            >
              {saved ? <BookmarkAddedRoundedIcon /> : <BookmarkAdd />}
            </IconButton>
            <IconButton size="sm" onClick={handleViewOnMap}>
              <LocationOnRounded />
            </IconButton>
          </Stack>
          <IconButton onClick={onClose} size="sm">
            <CloseRounded />
          </IconButton>
        </Box>

        {/* Main info */}
        <TextRow label="Scraped Address" value={scraped_address} />
        <TextRow label="Gmap Address" value={gmap_address} />
        <TextRow label="Area" value={area} />
        <TextRow label="Price" value={priceStr} />
        <TextRow label="Psf" value={psf} />

        {/* tags */}
        {currentTags.size > 0 && tags && tags.length > 0 && (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 2 }}>
            <Typography level="body-xs" sx={{ width: '100%' }}>
              Tags
            </Typography>
            {/* current tags */}
            {[...tags, 'add_tag'].map((tagId: string) => (
              <Chip
                key={tagId}
                size="sm"
                variant="outlined"
                sx={{ ml: -0.2 }}
                endDecorator={tagId === 'add_tag' ? <AddRounded /> : <ChipDelete onDelete={() => onRemoveTag(tagId)} />}
                onClick={tagId === 'add_tag' ? () => setIsAddTag(true) : undefined}
                disabled={!state.properties.has(property_id)}
              >
                {currentTags.get(tagId)?.name || tagId.replace('_', ' ')}
              </Chip>
            ))}
            {/* add tag */}
            {isAddTag && (
              <Autocomplete
                size="sm"
                value={tagValue}
                sx={{ width: '100%' }}
                onChange={async (evnet, newValue) => {
                  if (typeof newValue === 'string') {
                    await onAddTag(newValue);
                  } else if (newValue && newValue.inputValue) {
                    await onAddTag(newValue.inputValue);
                  } else {
                    await onAddTag(newValue.name);
                  }
                }}
                filterOptions={(options, params) => {
                  const filtered = tagFilter(options, params);
                  const { inputValue } = params;
                  const isExisting = options.some((option) => inputValue === option.name);
                  if (inputValue !== '' && !isExisting) {
                    filtered.push({
                      inputValue,
                      name: `Add "${inputValue}"`,
                    });
                  }
                  return filtered;
                }}
                selectOnFocus
                clearOnBlur
                freeSolo
                autoFocus
                options={Array.from(currentTags.values())}
                getOptionLabel={(option) => {
                  if (typeof option === 'string') {
                    return option;
                  }
                  if (option.inputValue) {
                    return option.inputValue;
                  }
                  return option.name;
                }}
                error={!!error}
                renderOption={(props, option) => (
                  <AutocompleteOption {...props} key={option.name}>
                    {option.name.startsWith('Add "') && (
                      <ListItemDecorator>
                        <Add />
                      </ListItemDecorator>
                    )}
                    {option.name}
                  </AutocompleteOption>
                )}
              />
            )}
          </Box>
        )}
        <Divider />

        {/* parse details */}
        <Stack sx={{ mt: 1 }}>
          {/* article 4 */}
          <TextRow
            label="Article 4 Restriction"
            value={article4Str}
          />
          {/* tenure */}
          <TextRow label="Tenure" value={toTitleCase(tenure || 'Unknown')} />
          {/* listed grade */}
          <TextRow label="Listed Grade" value={listed_grade ?? 'Not Listed'} />
          {/* property types */}
          <TextRow label="Property Type" value={property_types.map(toTitleCase).join(', ')} />
        </Stack>
        <Divider />

        {/* uprn details */}
        <Stack sx={{ mt: 1 }} rowGap={0}>
          <TextRow
            label="UPRN Metrics"
            info="Every addressable location in the UK has a Unique Property Reference Number (UPRN). The UPRN system is hierarchical. The UPRN of a location (e.g., building) which contains sub-locations (i.e., flats) is known as parent UPRN. An address might get matched to multiple entries in AddressBase, therefore may have multiple parent UPRNs."
            bold
          />
          <TextRow
            label="Number of Properties"
            value={prop_count_uprn?.toString() ?? 0}
            info="Combined total of child UPRNs across all of its parent UPRNs."
          />
          <TextRow
            label="Number of Leased Properties"
            value={lease_count_uprn?.toString() ?? 0}
            info="Combined total of leases across all of its top parent UPRNs."
          />
          <TextRow label="Parents Leased Percentage" value={perc_leased_uprn ? perc_leased_uprn + '%' : 'NA'} />
          <TextRow
            label="Listed UPRN"
            value={has_listed_uprn === true ? 'Has listed' : 'No listed' ?? 'Unknown'}
            info="Any listed UPRNs that share a common parent UPRN."
          />
          <TextRow
            label="Listed CAD"
            value={has_listed_cad === true ? 'Has listed' : 'No listed' ?? 'Unknown'}
            info="Any listed UPRNs in the same cadastre."
          />
        </Stack>
        <Divider />

        {/* date added and last seen */}
        <Box sx={{ mb: 1 }}>
          {date_added && (
            <>
              <Typography level="body-xs" sx={{ mb: 1, mt: 1 }}>
                Property First Seen On
              </Typography>
              <Typography level="body-xs" sx={{ ml: 1 }}>
                {date_added.split('T')[0]}
              </Typography>
            </>
          )}
          {last_seen && (
            <>
              <Typography level="body-xs" sx={{ mt: 2 }}>
                Property Last Seen on
              </Typography>
              <List size="sm" variant="plain">
                {urls &&
                  urls.map((url: any) => (
                    <ListItem key={url.url_id}>
                      <Link level="body-xs" href={url.url} key={url.url_id} target="_blank">
                        {url.name}
                      </Link>
                      <Typography level="body-xs">
                        {url.last_seen?.split('T')[0] || date_added.split('T')[0] || 'Unknown date'}
                      </Typography>
                    </ListItem>
                  ))}
              </List>
            </>
          )}
        </Box>

        <Divider sx={{ mb: 2 }} />
        {/* images */}
        {images && (
          <>
            <Typography level="body-xs">Images</Typography>
            <Box
              component="div"
              sx={(theme) => ({
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                maxWidth: '350px',
                maxHeight: '300px',
                overflow: 'hidden',
                mb: 2,
                padding: 1,
                border: `1px solid ${theme.colorSchemes.light.palette.divider}`,
                borderRadius: 'md',
              })}
            >
              <ImageCarousel images={images} height="140px" />
            </Box>
            <Button
              fullWidth
              variant="outlined"
              startDecorator={<ImageRounded />}
              onClick={() => setOpenModal(true)}
              sx={{ mb: 1 }}
            >
              Expand
            </Button>
          </>
        )}

        {/* brochures */}
        {brochures && brochures.length > 0 && (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 2, alignItems: 'center' }}>
            <Typography level="body-xs">Brochures</Typography>
            {brochures.map((brochureUrl: any, index: number) => (
              <Button
                key={brochureUrl}
                size="sm"
                component="a"
                sx={{ ml: 1 }}
                variant="outlined"
                href={brochureUrl}
                target="_blank"
              >
                {index + 1}
              </Button>
            ))}
          </Box>
        )}

        {/* description and features */}
        <AccordionGroup size="sm">
          <Accordion>
            <AccordionSummary>
              <Typography level="body-xs">Nearby Stations</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <List>
                {nearby_stations &&
                  nearby_stations.map((station: any) => (
                    <ListItem key={station.name}>
                      <Typography level="body-xs">{station.name.replace('Underground Station', '')}</Typography>
                      <Typography level="body-xs">{`${station.distance} ${station.unit}`}</Typography>
                    </ListItem>
                  ))}
              </List>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary>
              <Typography level="body-xs">Features</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <List marker="circle">
                {features &&
                  features.map((feature: any) => (
                    <ListItem key={feature}>
                      <Typography level="body-xs">{feature}</Typography>
                    </ListItem>
                  ))}
              </List>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary>
              <Typography level="body-xs">Description</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography level="body-xs">{description}</Typography>
            </AccordionDetails>
          </Accordion>
        </AccordionGroup>
      </Box>
    </>
  );
};

export default PropertyDetail;
