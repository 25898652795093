import { FC, useMemo } from 'react';
import usePropData from '../hooks/usePropData';
import { ResponsiveChartContainer } from '@mui/x-charts/ResponsiveChartContainer';
import { LinePlot } from '@mui/x-charts/LineChart';
import { BarPlot } from '@mui/x-charts/BarChart';
import { ChartsXAxis } from '@mui/x-charts/ChartsXAxis';
import { ChartsYAxis } from '@mui/x-charts/ChartsYAxis';
import { ChartsGrid } from '@mui/x-charts/ChartsGrid';
import { ChartsTooltip } from '@mui/x-charts/ChartsTooltip';
import { ChartsReferenceLine } from '@mui/x-charts/ChartsReferenceLine';

function buildRange(s: number, e: number): number[] {
    let list: number[] = [];
    for (let i = s; i <= e; i++) {
        list.push(i);
    }
    return list;
}

function formatCurrencyIntl(value: number, locale: string = 'en-GB', currency: string = 'GBP'): string {
    return new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: currency
    }).format(value);
}

const PsfDistribution: FC<{ percentile: number }> = ({ percentile }) => {

    const { state } = usePropData();
    const { psfDist } = state;
    const hasData = psfDist && psfDist.bins && psfDist.bins.length > 0;
    const psfIndex = useMemo(() => Math.floor(percentile / 10), [percentile]);


    if (!hasData) {
        return <div>No PSF Distribution data available</div>
    }

    console.log(psfDist)

    const linearRange = buildRange(psfDist['min'], psfDist['max']);
    const percentile_value = psfDist['percentiles'][psfIndex];
    const percentile_label = formatCurrencyIntl(percentile_value);


    return (
        <ResponsiveChartContainer
            series={[
                { type: 'bar', dataKey: 'count', xAxisKey: 'binAxis', color: '#c0c2c4' },
                { type: 'line', dataKey: 'perc', xAxisKey: 'binAxis', yAxisKey: 'percAxis', color: '#527196' }
            ]}
            xAxis={[
                {
                    id: 'binAxis',
                    scaleType: 'band',
                    dataKey: 'label',
                    label: 'PSF (£)',
                },
                {
                    id: 'linearAxis',
                    scaleType: 'linear',
                    data: linearRange,
                    min: psfDist['min'],
                    max: psfDist['max'],
                }
            ]}
            yAxis={[
                { id: 'countAxis', label: 'Count', dataKey: 'count' },
                { id: 'percAxis', label: 'Proportion (%)', scaleType: 'linear', dataKey: 'perc' }
            ]}
            dataset={psfDist['bins']}
            height={350}
            sx={{ width: '100%' }}
        >
            <ChartsGrid horizontal />
            <BarPlot />
            <LinePlot />
            <ChartsXAxis axisId='binAxis' />
            <ChartsYAxis axisId='countAxis' position='left' />
            <ChartsYAxis axisId='percAxis' position='right' />
            <ChartsReferenceLine
                label={percentile_label}
                labelAlign='start'
                x={percentile_value}
                axisId='linearAxis'
                labelStyle={{
                    fontWeight: 500,
                    fontSize: '14px',
                }}
                lineStyle={{
                    stroke: 'red',
                    strokeWidth: 2,
                    strokeDasharray: '4 4'
                }}
            />
            <ChartsTooltip sx={{
                '& .MuiChartsTooltip-paper': {
                    backgroudColor: 'white',
                    opacity: 1,
                    color: 'black',
                },
                '& .MuiChartsTooltip-tooltip': {
                    backgroudColor: 'white',
                    opacity: 1,
                    color: 'black',
                },
                '&. MuiChartsTooltip-root': {
                    backgroudColor: 'white',
                    opacity: 1,
                    color: 'black',
                }
            }}/>
        </ResponsiveChartContainer>
    );
}

export default PsfDistribution;